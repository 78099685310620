.or-root {
    width: 100%;
    display: flex;
    justify-content: center;
}

.or-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #676767;
    width: 50%;

    @media screen and (max-width: 768px) {
        width: 88%;
    }
}

.or-title {
    font-size: clamp(1rem, 4vw + 0.4rem, 2rem);
    margin: 0 32px;
}

.solid-line {
    border-top: 2px solid #676767;
    width: 100%;
}