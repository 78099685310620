.steps-root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2%;

  @media screen and (max-width: 480px) {
    padding: 0 8%;
  }
}


.steps-intro-content {
  font-size: clamp(1.2rem, 5vw + 0.4rem, 2.4rem);
  padding-left: 32px;

  @media screen and (max-width: 480px) {
    padding-left: 0;
  }
}

.steps-title {
  font-size: 1em;
  font-weight: 700;
}

.steps-description {
  font-size: 0.6em;
  color: #58595c;
  font-weight: 300;
  margin-top: 12px;
}

.steps-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 24px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.steps-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  margin: 0 32px;
  aspect-ratio: 1;
  justify-content: center;
  height: min-content;
  font-size: clamp(1.4rem, 1.5vw + 0.2rem, 2rem);
  padding: 24px;
  flex: 1;

  @media screen and (max-width: 880px) {
    margin: 0 8px;
    border-radius: 16px;
    padding: 24px 12px;
  }
  
  @media screen and (max-width: 480px) {
    margin: 12px 0;
    padding: 24px;
  }
}

.steps-card:nth-child(1) {
  background-color: #DFE4EC;
  align-self: flex-end;
  transform: scale(0.7);
  transition: 0.3s ease;

  @media screen and (max-width: 480px) {
    align-self: flex-start;
  }

}

.steps-card:nth-child(2) {
  background-color: #F9E4DB;
  align-self: center;
  transform: scale(0.7);
  transition: 0.3s ease;

  @media screen and (max-width: 480px) {
    align-self: flex-start;
  }
}

.steps-card:nth-child(3) {
  background-color: #F3F1EF;
  margin-bottom: 96px;
  align-self: flex-start;
  transform: scale(0.7);
  transition: 0.3s ease;

  @media screen and (max-width: 480px) {
    margin-bottom: 0;
    align-self: flex-start;
  }

}

.steps-card-number {
  background-color: #FFFFFF;
  width: 72px;
  aspect-ratio: 1;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: 700;
}

.steps-card-title {
  text-align: center;
  font-size: 0.85em;
  font-weight: 700;
  margin-top: 24px;
  
  @media screen and (max-width:768px){
    font-size: 0.75em;
  }

  @media screen and (max-width:480px){
    font-size: 0.9em;
  }
}

.steps-card-description {
  text-align: center;
  font-size: 0.6em;
  margin-top: 12px;
  color: #676767;
  font-weight: 300;

  @media screen and (max-width:480px){
    font-size: 0.65em;
  }
}

.steps-card:nth-child(1).scale-up:nth-child(1) {
  transform: scale(1);
  animation-delay: 1s;
  transition: 0.3s ease;
}

.steps-card:nth-child(2).scale-up:nth-child(2) {
  transform: scale(1);
  animation-delay: 5s;
  transition: 0.3s ease;
}

.steps-card:nth-child(3).scale-up:nth-child(3) {
  transform: scale(1);
  animation-delay: 10s;
  transition: 0.3s ease;
}

.steps-card:nth-child(1).scale-up:nth-child(1):hover {
  transform: scale(1.1);
  transition: 0.3s ease;
}
.steps-card:nth-child(2).scale-up:nth-child(2):hover {
  transform: scale(1.1);
  transition: 0.3s ease;
}
.steps-card:nth-child(3).scale-up:nth-child(3):hover {
  transform: scale(1.1);
  transition: 0.3s ease;
}