.contact-form-root {
    flex: 1;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 768px) {
        margin-top: 24px;
    }
}

.contact-form-button {
    align-items: center;
    display: flex;
    background-color: #B13633;
    border-radius: 8px;
    padding: 12px 28px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #FFFFFF;
    font-size: 16px;
    width: max-content;
}