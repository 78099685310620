.sidebar-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    z-index: 999;
    width:88%;
    right: 0;
    background-color: #EBF7FE;
    transition: 0.4s ease-in-out;
    justify-content: space-between;
    box-shadow: 0px 8px 16px #00000032;
    transition: 0.5s;
}

.sidebar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
}

.sidebar-header-image {
    width: 100%;
}

.sidebar-header-text {
    font-weight: 600;
    color: #33373a;
    font-size: 16px;
    width: max-content;
    margin-top: 24px;
    margin-left: 12px;
}

.sidebar-header-close {
    font-size: 32px;
}

.sidebar-navlinks-container {
    position: relative;
    height: 72%;
    justify-content: space-evenly;
    background-color: white;
    margin: 0 24px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
}

.link-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 90%;
    margin: 0 24px;
}

.links-image-container {
    position: absolute;
    right: -40px;
    top: -40px;
    border-radius: 200px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 16px #00000024;
    opacity: 0.4;
    width: 72%;
}

.links-image {
    aspect-ratio: 1;
}

.sidebar-navlink {
    font-size: 18px;
    text-decoration: none;
    color: #000000;
}

.sidebar-get-touch {
    background-image: linear-gradient(to right, #3684B7, #4C9F80);
    padding: 8px 16px;
    color: #FFFFFF;
    border-radius: 8px;
    text-decoration: none;
    display: flex;
    font-size: 18px;
    justify-content: center;
}

.reliable-text {
    font-size: 16px;
    align-self: center;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    margin-bottom: 4px;
}