.keyfigures-root {
  display: flex;
  justify-content: center;
}

.keyfigures-parent {
  width: 64%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media screen and (max-width: 1024px) {
    width: 72%;
  }
  
  @media screen and (max-width: 890px) {
    padding: 0 24px;
    width: 96%;
  }

  @media screen and (max-width: 480px) {
    padding: 0 12px;
    width: 100%;
  }
}

.keyfigures-title {
  font-size: clamp(1rem, 6vw + 0.1rem, 2rem);
  font-weight: 800;
}

.keyfigures-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  column-gap: 4%;
  margin-top: 24px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 6%;
  }
  
  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 6%;
  }
}

.figure-card {
  color: #000000;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f3f4f6d9;
  border-radius: 8px;
  padding: 24px;
  font-size: clamp(1rem, 4vw + 0.3rem, 2rem);
  transform: scale(0);
  transition: 0.3s ease;

}

.figure-card.scale-up {
  transform: scale(1);
  transition: 0.3s ease;
}

.figure-card.scale-up:hover {
  transform: scale(1.1);
  transition: 0.3s ease;
}

.figure-value {
  font-weight: 600;
}

.figure-description {
  font-size: 0.5em;
  align-items: flex-start;
  text-align: start;

  @media screen and (max-width: 480px){
    font-size: 0.6em;
  }
}