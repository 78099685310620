.contact-cta-root {
    display: flex;
    flex-direction: row;
    background-color: #234E9F;
    height: min-content;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}

.contact-cta-parent {
    display: flex;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 3%;
    font-size: calc(24px + (36 - 24) * ((100vw - 380px) / (1700 - 380)));

    @media screen and (max-width: 768px) {
        padding: 4%;
        flex-direction: column;
        align-items: flex-start;
    }

    @media screen and (max-width: 480px) {
        padding: 4% 6%;
    }
}

.contact-cta-text {
    color: #FFFFFF;
    letter-spacing: 1px;
    flex: 1;
}

.contact-cta-button {
    color: #FFF;
    text-decoration: none;
    background-color: #CB4342;
    font-size: 0.6em;
    display: flex;
    padding: 12px 32px;
    border-radius: 8px;
    width: max-content;
    align-items: center;
    letter-spacing: 1px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        margin-top: 12px;
        font-size: 0.6em;
    }

    @media screen and (max-width: 480px) {
        margin-top: 24px;
        margin-bottom: 16px;
        font-size: 0.7em;
    }
}


.contact-cta-button .contact-cta-button-icon {
    height: 0;
  width: 0;
  transform: rotate(-270deg);
  transition: 0.8s ease;
}

.contact-cta-button:hover .contact-cta-button-icon {
    height: 24px;
    width: 24px;
    margin-left: 12px;
    transform: rotate(-45deg);
    transition: 0.8s ease;
}