.accordian-item {
    background-color: #f5f5f5;
    margin-bottom: 4px;
    border: none;
    border-radius: 32px;
}

.acc-title {
    display: flex;
    border: none;
    width: 100%;
    font-size: calc(18px + (22 - 18) * ((100vw - 380px) / (1200 - 380)));
    padding: 12px 4px;
    font-weight: 350;
    justify-content: space-between;

}

.acc-toggle-title {
    color: #323232;
}

.acc-toggle-number {
    font-size: 2em;
    font-weight: 500;
    color: #d9d9d9;
}

.acc-body {
    font-size: calc(14px + (16 - 14) * ((100vw - 380px) / (1200 - 380)));
    border: none;
    font-weight: 350;
}