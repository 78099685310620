.home-gradient-background {
    background-image: linear-gradient(#EBF7FE, #FFFFFF);
}

.home-service-title {
    font-size: clamp(1.2rem, 6vw + 0.4rem, 2.6rem);
    color: #234E9F;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding: 72px 24px;
}