.trusted-tag-container {
  background-color: #dceffc;
  border-radius: 32px;
  padding: 4px 12px;
  color: #234e9f;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: 600;
  width: max-content;
  font-size: 0.2em;
  justify-content: center;

  @media screen and (max-width: 768px) {
    font-size: 0.2em;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.25em;
  }
}

.trusted-tag-star {
  font-size: 1.25em;
  color: #FFC700;
  margin-right: 4px;
}