.mobile-cta-root {
    height: min-content;
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

.mobile-cta-background {
    background-color: #f5f5f7;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;
    bottom: 12%;

    @media screen and (max-width: 480px) {
        bottom: 6%;
    }
}

.mobile-cta-image {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media screen and (max-width: 480px) {
        width: 72%;
        align-self: flex-start;
    }
}

.mobile-cta-content {
    display: block;
    align-self: center;
    flex: 1.5;
    margin-left: 32px;
    font-size: clamp(1rem, 2vw + 1rem, 2rem);

    @media screen and (max-width: 768px) {
        margin: 0 24px;
        padding: 24px;
    }

    @media screen and (max-width: 480px) {
        margin: 0 24px;
        padding: 24px 0px;
        margin-top: 24px;
    }
}

.mobile-description {
    color: #2c2c2c;
    font-weight: 700;
    text-align: start;
    margin-bottom: 40px;
    margin-right: 12%;
    margin-top: 24px;

    @media screen and (max-width: 480px) {
        margin-right: 0;
        margin-top: 40px;
    }
}

.mobile-coming-soon {
    font-size: 1em;
    color: #a872ea;
}

.cta-tag {
    background-color: #f0e7fa;
    color: #a872ea;
    padding: 4px 12px;
    border-radius: 100px;
    font-weight: 700;
    font-size: 0.6em;
    display: inline-block;
}

.mobile-cta-download {
    width: 32%;

    @media screen and (max-width: 768px) {
        width: 56%;
    }

    @media screen and (max-width: 480px) {
        width: 80%;
    }
}