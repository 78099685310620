.service-root {
  height: min-content;
  width: 100%;
  transform: scale(0.8);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.service-root.scale-up {
  transform: scale(1);
}

.service-background-content-container {
  flex: 1;
  width: 100%;
  bottom: 0;
  top: 8%;
  z-index: -1;
  background-color: var(--container-color)
    /*#eef8fa*/
  ;
  position: absolute;
  border-radius: var(--border-radius);

  @media screen and (max-width: 768px) {
    top: 0%;
    border-radius: 0 0 32px 32px;
  }
}

.service-background-image-container {
  flex: 0.6;
  z-index: -1;
  height: 100%;
  width: 100%;
  background-color: var(--image-bg-color);
  border-radius: 32px;

  @media screen and (max-width: 1440px) {
    flex: 1;
  }
}

.service-background-image-color {
  flex: 0.6;
  background-color: var(--image-bg-container-color);
  z-index: -1;
  bottom: 0;
  top: 8%;
  width: 100%;
  border-radius: var(--border-radius);
  position: absolute;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    /* top: 0%; */
    flex: 1;
    border-radius: 32px 32px 0 0;
  }
}

.service-parent {
  display: flex;
  flex-direction: var(--flex-direction);
  height: 100%;
  margin: 0 2%;
  z-index: -2;
  border-radius: 32px;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    margin: 0 16px;
  }
}

.service-content-parent {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  justify-content: space-between;
}

.service-image-parent {
  flex: 0.6;
  position: relative;

  @media screen and (max-width: 1440px) {
    flex: 0.8;
  }
}

.service-image-logo-container {
  padding: 1.2em;
  background-color: white;
  border-radius: 100px;
  height: min-content;
  aspect-ratio: 1;
  height: 17%;
  align-self: var(--align-self);
  margin-left: 4%;
  margin-right: 4%;
  box-shadow: 0px 4px 16px #00000024;

  @media screen and (max-width: 768px) {
    margin-top: 4%;
    margin-left: 3%;
    padding: 3%;
    width: 16%;
  }

  @media screen and (max-width: 480px) {
    margin-left: 3%;
    margin-top: 8%;
    width: 18%;
  }
}

.service-image-logo {
  object-fit: contain;
  height: 100%;
}

.service-content-group {
  margin: 0 4% 0 4%;
  height: 100%;
  /* font-size: clamp(1rem, 4vw + 1rem, 4rem); */
  font-size: calc(32px + (53 - 32) * ((100vw - 380px) / (1200 - 380)));
}

.service-image {
  aspect-ratio: 1;
  height: 100%;
  flex: 0.6;
  object-fit: cover;
  border-radius: 32px;
  overflow: hidden;
  @media screen and (max-width: 1380px) {
    border-radius: 64px 64px 32px 32px;
    
  }
  @media screen and (max-width: 786px) {
    border-radius: 32px;
  }
}

.service-content-title {
  font-size: 1em;
  font-weight: 500;
  color: var(--title-color);
  margin-bottom: 8px;
  margin-top: 16px;

  @media screen and (max-width: 480px) {
    font-size: 1.1em;
    margin-top: 20px;
  }
}

.service-content-subtitle {
  font-size: 0.4em;
  color: var(--subtitle-color);
  font-weight: 400;
  margin-bottom: 14px;

  @media screen and (max-width: 768px) {
    font-size: 0.6em;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.7em;
  }
}

.service-content-description {
  font-size: 0.3em;
  color: var(--description-color);
  margin-bottom: 24px;

  @media screen and (max-width: 768px) {
    font-size: 0.45em;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.5em;
    margin-bottom: 32px;
  }
}

.service-know-more {
  padding: 2.5% 6%;
  font-size: 1.2em;
  width: max-content;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1px;
  z-index: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  align-self: var(--align-self);
  background-color: var(--cta-color);
  border-radius: var(--cta-border-radius);


  @media screen and (max-width: 768px) {
    align-self: flex-start;
    padding: 16px 32px;
    margin-top: 48px;
    font-size: 1.2em;
    border-radius: 0 24px 0 24px;
  }

  @media screen and (max-width: 480) {
    font-size: 1em;
  }
}

.service-know-more .cta-icon {
  height: 0;
  width: 0;
  transform: rotate(-270deg);
  transition: 0.8s ease;
}

.service-know-more:hover .cta-icon {
  height: 24px;
  width: 24px;
  transform: rotate(-45deg);
  transition: 0.8s ease;
  margin-left: 8px;
}

.service-pointer-text {
  font-size: 0.3em;
  font-weight: 300;
  text-align: start;
  display: flex;
  color: var(--service-point-color);

  @media screen and (max-width: 768px) {
    font-size: 0.45em;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.5em;
  }
}

.service-pointer-icon {
  font-size: 0.85em;
  margin-top: 1.3%;
  margin-right: 4px;

  @media screen and (max-width: 768px) {
    margin-top: 1%;
    font-size: 0.9em;

  }
}

.content-pointer-grid {
  margin-top: 2%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  align-content: flex-start;
  column-gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}