.reliable-cta-countour {
  background-image: url("../../../../assets/png/countour.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  position: absolute;
  z-index: 0;
}

.reliable-cta-parent {
  display: flex;
  flex-direction: row;
  justify-self: space-between;
  background-image: linear-gradient(90deg, #3484b5, #4a9d80);
  overflow: hidden;
  margin: 0 2%;
  border-radius: 32px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin: 0 4%;
  }

}

.reliable-cta-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0.8;
  padding: 24px 24px;
  font-size: clamp(1rem, 2.2vw + 0.7rem, 2.5rem);
  margin-right: 12%;

  @media screen and (max-width:768px) {
    flex: 0.9;
    margin-right: 1%;
  }
}

.reliable-cta-title {
  color: #ffffff;
  margin-bottom: 12px;
}

.reliable-cta-suite-title {
  text-decoration: underline;
}

.relaible-cta-button {
  margin-top: 24px;
  border-radius: 32px;
  background-color: #00000022;
  padding: 8px 24px;
  color: #ffffff;
  font-size: 0.6em;
  font-weight: 400;
  transition: 0.3s ease;
  transform: scale(1);
  margin-bottom: 24px;
  text-decoration: none;

  @media screen and (max-width: 480px) {
    font-size: 0.8em;
    padding: 8px 16px;
  }
}

.relaible-cta-button:hover {
  transform: scale(1.1);
  transition: 0.3s ease;
}

.reliable-suite-container {
  display: flex;
  flex: 0.2;
  justify-content: flex-end;

  @media screen and (max-width: 1024px) {
    flex: 0.35;
  }

  @media screen and (max-width: 768px) {
    width: 40%;
    height: 72%;
    align-self: flex-end;
    z-index: -1;
  }

  @media screen and (max-width: 480px) {
    width: 50%;
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1.3);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.3);
  }
}

.animated-element {
  animation: scaleAnimation 3s ease infinite;
  height: 100%;
  object-fit: cover;
}