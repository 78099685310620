.about-us-title-gradient {
    display: flex;
    color: #234E9F;
    justify-content: center;
    background-image: linear-gradient(#EBF7FE, #FFFFFF);
    font-size: calc(28px + (36 - 28) * ((100vw - 380px) / (1200 - 380)));
    padding-top: 12%;

    @media screen and (max-width: 880px) {
        padding-top: 24%;
    }

    @media screen and (max-width: 480px) {
        padding-top: 32%;
    }
}

.about-us-span {
    color: #B13633;
}

.about-us-desccription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2% 8%;
    font-size: calc(18px + (19 - 18) * ((100vw - 380px) / (1200 - 380)));
    color: #000000;
    text-align: center;
    font-weight: 350;

    @media screen and (max-width: 480px) {
        padding: 2% 4%;
    }
}

.process-parent {
    display: flex;
    flex-direction: row;
    padding: 0 4%;
    margin-bottom: 160px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 80px;
    }
}

.process-image-container {
    flex: 0.8;
    width: 100%;
    height: 80%;
    border-radius: 24px;
    overflow: hidden;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    
    @media screen and (max-width: 768px) {
        justify-content: flex-start;
    }
}

.process-image {
    object-fit: cover;
    aspect-ratio: 1;
    height: 90%;
    border-radius: 24px;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
}

.process-text-container {
    flex: 1;
    width: 100%;
    padding: 0 2%;

    @media screen and (max-width: 768px) {
        padding: 0 1%;
        padding-top: 4%;
    }
}

.our-process-title-container {
    display: flex;
    color: #234E9F;
    justify-content: center;
    font-size: calc(24px + (32 - 24) * ((100vw - 380px) / (1200 - 380)));
    align-items: center;
}

.about-divider {
    width: 50%;
    height: 1px;
    background-color: #e8e8e8;
    margin: 56px auto;

    @media screen and (max-width: 768px) {
        margin: 24px auto;
        margin-bottom: 40px;
    }
}