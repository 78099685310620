.cta-root {
  display: flex;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 32px;
  position: relative;
}

.cta-human {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  right: 32px;

  @media screen and (max-width: 768px) {
    right: 0;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
}

.cta-service-strip {
  display: flex;
  flex-direction: row;
  background-color: #F1F2F9;
  width: 100%;
}

.cta-service-container {
  display: flex;
  flex-direction: row;
  flex: 0.7;

  @media screen and (max-width: 840px) {
    flex: 0.8;
  }

  @media screen and (max-width: 768px) {
    flex: 0.9;
  }

  @media screen and (max-width: 480px) {
    flex: 1;
    flex-direction: column;
    padding: 12px 0;
  }
}

.cta-service-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 1% 6%;
  font-size: clamp(1rem, 1vw + 0.2rem, 1.25rem);

  @media screen and (max-width: 840px) {
    width: 50%;
    padding: 2%
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 2%;
  }


  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 6px 24px;
  }

}

.cta-service-item-icon {
  font-size: 1em;
  color: 232B5D;
}

.cta-service-item-text {
  font-weight: 400;
  font-size: 0.9em;

  @media screen and (max-width:480px) {
    font-size: 1em;
  }
}

.cta-content {
  display: flex;
  flex-direction: row;
  background-color: #234E9F;
  width: 100%;
  position: relative;
  height: 100%
}

.cta-content-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.cta-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0.8;
  z-index: 1;
  padding: 2% 3%;
  font-size: calc(28px + (36 - 28) * ((100vw - 380px) / (1700 - 380)));

  @media screen and (max-width: 786px) {
    flex: 0.6
  }

  @media screen and (max-width: 480px) {
    flex: 1;
    padding: 32px 24px;
  }
}

.cta-content-text {
  color: #FFFFFF;
}

.cta-know-more {
  color: #FFF;
  background-color: #CB4342;
  font-size: 0.6em;
  display: flex;
  padding: 8px 20px;
  border-radius: 8px;
  width: max-content;
  align-items: center;
  letter-spacing: 1px;
  justify-content: center;
  margin-top: 16px;
  text-decoration: none;
}

.cta-know-more .cta-icon {
  height: 0;
  width: 0;
  transform: rotate(-270deg);
  transition: 0.8s ease;
}

.cta-know-more:hover .cta-icon {
  height: 24px;
  width: 24px;
  margin-left: 12px;
  transform: rotate(-45deg);
  transition: 0.8s ease;
}