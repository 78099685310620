.service-pills {
  display: flex;
  flex-direction: row;
}

.pills {
  padding: 4px 20px;
  border-radius: 32px;
  color: #ffffff;
  font-size: 0.2em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 4% 2% 0 0;

  @media screen and (max-width: 480px) {
    font-size: 0.25em;

  }
}