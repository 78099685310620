.contact-form_parent {
    display: flex;
    flex-direction: row;
    padding: 24px;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
}

.contact-header-text-parent {
    background-image: linear-gradient(#EBF7FE, #FFFFFF);
}

.contact-header-text {
    font-size: clamp(1.2rem, 6vw + 0.4rem, 2.6rem);
    color: #234E9F;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 24px;
    text-align: center;
    margin-top: 72px;
    margin-bottom: 72px;
}