.navbar-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 4;
  padding: 12px 24px;
  @media screen and (max-width:480px) {
    padding: 12px 12px;
  }
}

.navbar-parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 16px #00000024;
  height: 72%;
  border-radius: 12px;
  padding: 12px 16px;
  background-color: #FFFFFF;

  @media screen and (max-width:768px) {
    padding: 24px 16px;
  }
}

.nav-link-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(12px + (15 - 12) * ((100vw - 380px) / (1200 - 380)));

  @media screen and (max-width:768px) {
    display: none;
  }
}

.side-menu {
  font-size: 24px;
  display: none;
  
  @media screen and (max-width:768px) {
    display: block;
  }
  @media screen and (max-width:480px) {
    font-size: 20px;
    display: block;
  }
}

.navbar-logo {
  position: absolute;
  height: 40%;
  aspect-ratio: 1;
  display: flex;
  transform: scale(5) translateX(8px) translateY(0px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  @media screen and (max-width:480px) {
    transform: scale(5) translateX(2px) translateY(0px);
    height: 28%;
  }
}

.navbar-logo.scroll {
  transform: scale(3) translateX(2px) translateY(0px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  @media screen and (max-width:480px) {
    transform: scale(3.6) translateX(0px) translateY(0px);
    height: 28%;
  }
}

.logo-text {
  font-weight: 600;
  color: #33373a;
  text-decoration: none;
  font-size: calc(12px + (14 - 12) * ((100vw - 380px) / (1200 - 380)));
  width: max-content;
  transform: translateX(160px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  @media screen and (max-width:480px) {
    transform: translateX(80px);
    font-size: 12px;

  }
}

.logo-text.scroll {
  transform: translateX(80px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  @media screen and (max-width:480px) {
    transform: translateX(48px);

  }
}