.floating-service-parent {
  position: absolute;
  background-color: #ffffff;
  align-content: center;
  padding: 1.5% 2.5%;
  left: 0;
  right: 0;
  width: 56%;
  bottom: 0%;
  z-index: 3;
  margin-left: auto;
  margin-right: auto;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 16px #00000024;

  @media screen and (max-width: 1180px) {
    width: 64%;
    box-shadow: 0px 4px 16px #00000024;
  }

  @media screen and (max-width: 880px) {
    bottom: 16px;
    padding: 3%;
    width: 92%;
    box-shadow: 0px 4px 16px #00000024;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
}

.floating-service-text-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  font-size: calc(20px + (26 - 20) * ((100vw - 380px) / (1700 - 380)));

  @media screen and (max-width: 480px) {
    margin-bottom: 32px;
    align-items: center;
  }
}

.floating-service-title {
  font-size: 1em;
  font-weight: 600;
  color: #163155;
}

.floating-service-description {
  font-size: 0.6em;
  font-weight: 400;
  color: #9c9c9c;
}

.floating-services-image-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    width: 80%;
  }
}

.floating-services-image-container {
  width: calc(40px + (48 - 40) * ((100vw - 380px) / (1700 - 380)));
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-services-image {
  object-fit: fill;
  width: 100%;
}