.hero-root {
  width: 100%;
  padding-bottom: 56px;
  position: relative;

  @media screen and (max-width: 1180px) {
    padding-bottom: 100px;
  }

  @media screen and (max-width: 880px) {
    padding-bottom: 144px;
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 200px;
  }
}

.hero-parent {
  display: flex;
  flex-direction: row;
  padding: 0% 4%;

  @media screen and (max-width: 880px) {
    flex-direction: column;
  }
}

.hero-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  justify-content: flex-start;
  /* background-color: red; */
  padding-top: 32px;
  /* font-size: clamp(1rem, 5vw + 1rem, 5.2rem); */
  font-size: calc(48px + (83 - 48) * ((100vw - 380px) / (1700 - 380)));

  @media screen and (max-width: 880px) {
    margin-bottom: 24px;
  }
}

.hero-image-container {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  position: relative;
  border-radius: 32px;

  @media screen and (max-width: 880px) {
    margin-top: 12px;

  }
}

.hero-money-image {
  border-radius: 32px;
  width: 88%;

  @media screen and (max-width: 880px) {
    width: 100%;
  }
}

.hero-know-more {
  padding: 2.5% 6%;
  font-size: 1.2em;
  width: max-content;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1px;
  z-index: 1;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  text-decoration: none;
  background-color: #B13633;
  border-radius: 24px 0 24px 0;

  @media screen and (max-width: 768px) {
    padding: 16px 32px;
    font-size: 1.2em;
  }

  @media screen and (max-width: 480px) {
    font-size: 1em;
  }
}

.hero-know-more .cta-icon {
  height: 0;
  width: 0;
  transform: rotate(-270deg);
  transition: 0.8s ease;
}

.hero-know-more:hover .cta-icon {
  height: 24px;
  width: 24px;
  transform: rotate(-45deg);
  transition: 0.8s ease;
  margin-left: 8px;
}

.header-title {
  font-weight: 500;
  text-align: start;
  line-height: 100%;
  background-image: linear-gradient(to right, #3584b6, #4b9e80);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 4%;
  margin-bottom: 24px;
  font-size: 1em;

  @media screen and (max-width: 880px) {
    margin-bottom: 12px;
    margin-top: 32px;
  }

  @media screen and (max-width: 880px) {
    margin-bottom: 24px;
    margin-top: 32px;
  }
}

.header-description {
  color: #163155;
  font-weight: 300;
  font-size: 0.3em;
  text-align: start;
  padding-right: 14%;
  margin-bottom: 32px;

  @media screen and (max-width: 880px) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.33em;
  }
}