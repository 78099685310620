.cta-service-root {
    display: flex;
    flex-direction: row;
    background-color: #F1F2F9;
    padding: 1% 4%;

    @media screen and (max-width: 480px) {
        flex-direction: column;
        padding: 4% 4% 0 4%;
    }
}

.cta-service-item-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: clamp(1rem, 1vw + 0.2rem, 1.25rem);;

    @media screen and (max-width: 480px) {
        margin-bottom: 12px;
    }
}

.cta-service-item-text {
    color: #232B5D;
    font-size: 1em;
    margin-left: 4px;
}

.cta-service-item-description {
    padding: 0 4px;
    font-weight: 600;
    color: #232B5D;
    font-size: 1em;
}