.footer-root {
    background-color: #234E9F;

    @media screen and (max-width: 480px) {
        /* padding-top: 24px; */
    }
}

.footer-container {
    margin: 0 24px;
    background-color: #FFF;
    border-radius: 24px;
    padding: 24px 56px;

    @media screen and (max-width: 960px) {
        padding: 32px;
    }

    @media screen and (max-width: 768px) {
        padding: 32px;
    }

    @media screen and (max-width: 480px) {
        margin: 0 16px;
        padding: 24px;
    }
}

.footer-service-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-start;
    column-gap: 4px;

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 16px;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.footer-service-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4px;
    font-size: clamp(1rem, 4vw + 0.4rem, 1.4rem);
}

.footer-service-header {
    font-weight: 700;
    font-size: 1em;
    color: #234E9F;
}

.footer-service-link {
    font-size: 0.65em;
    font-weight: 400;
    color: #676767;
    text-decoration: none;
}

.footer-contact-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.footer-contact-title {
    color: #676767;
    font-size: 14px;
}

.footer-contact-description {
    color: #234E9F;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.footer-data-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-end;
    column-gap: 4px;

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 16px;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.footer-copyright {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.footer-created-by {
    font-size: 14px;
    color: #7c7c7c;
    display: flex;
    text-decoration: none;

    @media screen and (max-width: 480px) {
        margin-bottom: 12px;
    }
}

.footer-copyright-text {
    font-size: 14px;
    color: #7c7c7c;
}

.footer-arc {
    background-color: #d9d9d922;
    width: 100%;
    color: #D9D9D9aa;
    border-radius: 50% 50% 0 0;
    font-size: 20px;
    padding: 16px 0;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 480px) {
        padding: 32px 0;

    }
}

.footer-created-logo {
    margin-left: 4px;
}

.footer-created-logo:hover {
    color: #234E9F;
    font-weight: 500;
}