.school-service-screen-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.school-gap {
    width: 100%;
    height: 112px;

    @media screen and (max-width: 880px) {
        height: 140px;
    }

    @media screen and (max-width: 480px) {
        height: 96px;
    }
}

.school-service-header {
    width: 100%;
    position: relative;
}

.school-service-header-video {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 2;

    @media screen and (max-width: 768px) {
        aspect-ratio: 1.5;
    }

    @media screen and (max-width: 480px) {
        aspect-ratio: 0.75;
    }
}

.school-service-title-container {
    height: 56%;
    width: 100%;
    position: absolute;
    bottom: 0;
    /* background-color: red; */
    background-image: linear-gradient(360deg, #EBF7FE 6rem, #00000000);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 24px;
    padding-left: 48px;
    padding-bottom: 0;
    font-size: calc(32px + (54 - 32) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width: 480px) {
        padding-left: 12px;
    }
}

.school-service-title-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.school-service-header-image-container {
    background-color: #FFFFFF;
    flex: 0.12;
    overflow: hidden;
    aspect-ratio: 1;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    box-shadow: 0px 4px 16px #00000024;

    @media screen and (max-width: 1024px) {
        flex: 0.2;
    }

    @media screen and (max-width: 480px) {
        flex: 0.4;
    }
}

.school-service-header-image {
    height: 100%;
    aspect-ratio: 1;
    padding: 20%;
}

.school-service-header-text {
    font-size: 1em;
    flex: 1;
    line-height: 100%;
    font-weight: 500;
    color: #2E5E67;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.school-service-header-subtitle {
    font-size: 0.5em;
    font-weight: 400;
    color: #2E5E67aa;
}

.school-info-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 8%;
    font-size: calc(32px + (40 - 32) * ((100vw - 380px) / (1200 - 380)));
}

.school-info-parent {
    display: flex;
    flex-direction: var(--school-service-reverse);
    align-items: flex-start;
    padding: 0 4%;
    overflow: hidden;

    @media screen and (max-width: 880px) {
        margin-bottom: 4%;
    }

    @media screen and (max-width: 480px) {
        flex-direction: column-reverse;
    }
}

.school-info-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    margin: 32px 0;
    padding: var(--clinic-content-padding);
}

.school-info-title {
    font-size: 1em;
    color: #2E5E67;
    padding: 0 24px;

    @media screen and (max-width: 880px) {
        padding: var(--school-service-padding);
        margin-top: 24px;
    }

    @media screen and (max-width: 480px) {
        padding: 0 4%;
    }
}

.school-info-description {
    font-size: 0.45em;
    margin: 24px 0;
    color: #727272;
    padding: 0 24px;

    @media screen and (max-width: 880px) {
        padding: var(--school-service-padding);
    }

    @media screen and (max-width: 480px) {
        font-size: 0.47em;
        padding: 0 4%;
    }
}

.school-info-pointers-main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* column-gap: 12px; */
    row-gap: 8px;
    width: 100%;
    /* background-color: red; */

    @media screen and (max-width: 880px) {
        display: none;
    }

    @media screen and (max-width: 480px) {
        display: grid;
        /* column-gap: 2px; */
        row-gap: 4px;
        grid-template-columns: repeat(1, 1fr);
    }
}

.school-info-pointers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    display: none;

    @media screen and (max-width: 880px) {
        display: grid;
        padding: 0 12px;
    }

    @media screen and (max-width: 480px) {
        display: none;
        /* column-gap: 2px; */
        row-gap: 4px;
        grid-template-columns: repeat(1, 1fr);
    }
}

.school-info-point {
    font-size: 0.4em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: #7f7f7f;
    padding: 12px 24px;
    height: max-content;
    cursor: pointer;
    font-weight: 400;
    animation: fadeIn 0.5s ease-out forwards;

    @media screen and (max-width: 480px) {
        font-size: 0.45em;
        padding: 12px 4%;
    }
}

.school-info-point-icon {
    margin-right: 6px;
    margin-top: 2px;
    min-width: 1em;
}

.school-info-point:hover {
    background-color: #EEF8FA;
    border-radius: 32px;
    color: #648990;
    margin: 4px 16px;
    box-shadow: 0px 4px 16px #00000032;
    margin-right: 0;
    transition: 0.4s ease;
}

.school-info-image {
    flex: 0.6;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 24px;
    position: relative;
    margin: 32px 0;
    box-shadow: 0px 4px 32px #00000040;

    @media screen and (max-width: 880px) {
        flex: 1;
    }
}

.school-info-image-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #00000072;
}

.school-info-image-button {
    width: 24%;
    aspect-ratio: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    border-radius: 100%;
    background-color: #00000072;
    display: flex;
    align-items: center;
    justify-content: center;
}

.school-info-image-play {
    font-size: 64px;
    color: #FFFFFFaa;
    margin-left: 12px;
}