.testimonial-root {
  background-color: #F3F4F6;
  height: min-content;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    padding: 32px 20px;
    align-items: flex-start;
  }

  @media screen and (max-width: 480px) {
    padding: 40px 12px;
  }
}

.testimonial-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px 32px;
  flex: 1;
  font-size: calc(32px + (56 - 32) * ((100vw - 380px) / (1700 - 380)));
}

.testimonial-content-title {
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 12px;
  font-weight: 600;

  @media screen and (max-width: 880px) {
    font-size: 1.1em;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.2em;
  }
}

.testimonial-content-description {
  margin-top: 8px;
  font-size: 0.3em;
  font-weight: 400;
  color: #676767;

  @media screen and (max-width: 880px) {
    font-size: 0.4em;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.5em;
  }
}

.testimonial-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 32px;
  flex: 2;

  @media screen and (max-width: 820px) {
    padding: 8px 16px;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
}


.testimonial-card {
  display: flex;
  margin-left: 16px;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  background-color: #fff;
  border-radius: 24px;
  /* font-size: clamp(1rem, 1.2vw + 0.4rem, 1.2rem); */
  font-size: calc(16px + (18 - 16) * ((100vw - 380px) / (1700 - 380)));
  flex: 1;
  height: 100%;
  transform: scale(1);
  transition: all 0.3s ease;

  @media screen and (max-width: 820px) {
    padding: 24px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 16px;
    margin-left: 0px;
  }
}

.testimonial-card:hover {
  box-shadow: 0px 4px 16px #00000024;
  transform: scale(1.01);
  transition: all 0.3s ease;
}

.testimonial-quote {
  height: 28%;

  @media screen and (max-width: 820px) {}
}

.testimonial-card-text {
  color: #212427;
  font-weight: 400;
  text-align: start;
}

.testimonial-card-client-name {
  color: #CB4342;
  font-weight: 600;
  font-size: 1em;

}

.testimonial-card-company-name {
  color: #959FA7;
  font-weight: 400;
  font-size: 0.9em;
}